export default {
  CUSTOMERS_LIST: "Liste des clients",
  ADD_CUSTOMER: "Ajouter un client",
  CUSTOMER_ADDED: "Client ajouté",
  EDIT_CUSTOMER: "Modifier un client",
  CUSTOMER_UPDATED: "Client mis à jour",
  DELETE_THIS_CUSTOMER: "Supprimer ce client ?",
  CUSTOMER_DELETED: "Client supprimé",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Même adresse que le client",
  CUSTOMER_TYPE_INDIVIDUAL: "Particulier",
  CUSTOMER_TYPE_COMPANY: "Entreprise",
  VIEW_CUSTOMER: "Client",
  LAST_NAME: "Nom du client",
  FIRST_NAME: "Prénom du client",
  EMAIL: "Courriel du client",
  PHONE: "Numéro de téléphone du client",
  COUNTRY: "Pays de résidence",
  ADDRESS: "Adresses du client",
  CUSTOMER_DETAILS: "Détails client",
  CUSTOMER_INFORMATION: "Information client",
};
