import permissions from "../constants/permissions";
import ConfigurationModuleLayout from "../views/Pages/ConfigurationModule/ConfigurationModuleLayout";
import ConfigurationModuleMenu from "../views/Pages/ConfigurationModule/ConfigurationModuleMenu";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListSpotCategoryPage = () =>
  import(
    "@/views/Pages/ConfigurationModule/SpotCategoryManagement/ListSpotCategoryPage.vue"
  );
const ListSpotTypePage = () =>
  import(
    "@/views/Pages/ConfigurationModule/SpotTypeManagement/ListSpotTypePage.vue"
  );
const ListEquipmentCategoryPage = () =>
  import(
    "@/views/Pages/ConfigurationModule/EquipmentCategoryManagement/ListEquipmentCategoryPage.vue"
  );
const ListEquipmentPage = () =>
  import(
    "@/views/Pages/ConfigurationModule/EquipmentManagement/ListEquipmentPage.vue"
  );
// const ListPricingRulePage = () =>
//   import("@/views/Pages/ConfigurationModule/PricingRuleManagement/ListPricingRulePage.vue");

const ViewOrganizationPage = () =>
  import(
    "@/views/Pages/DefaultModule/OrganizationManagement/ViewOrganizationPage.vue"
  );
const EditOrganizationPage = () =>
  import(
    "@/views/Pages/DefaultModule/OrganizationManagement/EditOrganizationPage.vue"
  );

const ConfigurationPage = () =>
  import("@/views/Pages/ConfigurationModule/ConfigurationPage.vue");
//const ConfigurationPage = () => import("@/views/Pages/ConfigurationModule/SpotCategoryManagement/ListSpotCategoryPage.vue");

let spotCategoryManagementPages = {
  path: "spot-categories",
  components: {
    default: ConfigurationModuleLayout,
    SidebarSecondLevelMenu: ConfigurationModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SpotCategories",
      components: { default: ListSpotCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SPOT_CATEGORIES,
        hasLevel2: true,
      },
    },
  ],
};

let spotTypeManagementPages = {
  path: "spot-types",
  components: {
    default: ConfigurationModuleLayout,
    SidebarSecondLevelMenu: ConfigurationModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SpotTypes",
      components: { default: ListSpotTypePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SPOT_TYPES,
        hasLevel2: true,
      },
    },
  ],
};

let equipmentCategoryManagementPages = {
  path: "equipment-categories",
  components: {
    default: ConfigurationModuleLayout,
    SidebarSecondLevelMenu: ConfigurationModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List EquipmentCategories",
      components: { default: ListEquipmentCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_EQUIPMENT_CATEGORIES,
        hasLevel2: true,
      },
    },
  ],
};

let equipmentManagementPages = {
  path: "equipments",
  components: {
    default: ConfigurationModuleLayout,
    SidebarSecondLevelMenu: ConfigurationModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Equipments",
      components: { default: ListEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_EQUIPMENTS,
        hasLevel2: true,
      },
    },
  ],
};
// let pricingRulesPages = {
//   path: "pricing-rules",
//   components: {
//     default: ConfigurationModuleLayout,
//     SidebarSecondLevelMenu: ConfigurationModuleMenu,
//   },
//   children: [
//     {
//       path: "",
//       name: "List PricingRules",
//       components: { default: ListPricingRulePage },
//       meta: {
//         middleware: authorized,
//         permission: permissions.PERM_VIEW_ANY_PRICING_RULES,
//         hasLevel2: true,
//       },
//     },
//   ],
// };

let OrganizationManagementPages = {
  path: "organization",
  component: ConfigurationModuleLayout,
  children: [
    {
      path: "",
      name: "View Organization",
      components: { default: ViewOrganizationPage },
      meta: {
        middleware: authenticated,
        permission: permissions.PERM_VIEW_OWN_ORGANIZATIONS,
      },
    },
    {
      path: "change",
      name: "Edit Organization",
      components: { default: EditOrganizationPage },
      meta: {
        middleware: authenticated,
        permission: permissions.PERM_EDIT_OWN_ORGANIZATIONS,
      },
    },
  ],
};

const configurationModuleRoutes = [
  {
    path: "",
    components: {
      default: ConfigurationModuleLayout,
      SidebarSecondLevelMenu: ConfigurationModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Configuration Page",
        components: { default: ConfigurationPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  spotTypeManagementPages,
  spotCategoryManagementPages,
  equipmentCategoryManagementPages,
  equipmentManagementPages,
  OrganizationManagementPages,
  // pricingRulesPages
];

export default configurationModuleRoutes;
