import {
  FORM_TYPE_DEFAULT,
  FORM_TYPE_LAND,
  FORM_TYPE_PROPERTY,
} from "../../constants/common";

export default {
  SPOT_CATEGORY_LIST: "Liste des catégories",
  ADD_SPOT_CATEGORY: "Ajouter une catégorie",
  SPOT_CATEGORY_ADDED: "Catégorie ajouté",
  EDIT_SPOT_CATEGORY: "Modifier la catégorie",
  SPOT_CATEGORY_UPDATED: "Catégorie mise à jour",
  DELETE_THIS_SPOT_CATEGORY: "Voulez-vous supprimer la catégorie sélectionné ?",
  DELETE_SPOT_CATEGORY_SUCCESS:
    "La suppression de la catégorie a été effectuée avec succès",
  SPOT_CATEGORY_DELETED: "Catégorie supprimée",
  SPOT_CATEGORY_NAME: "Nom de la catégorie",
  SPOT_FORM_TYPE: "Type de formulaire",
  SPOT_FORM_TYPE_DEFAULT: "Par défaut",
  SPOT_FORM_TYPE_LAND: "Terrain",
  SPOT_FORM_TYPE_PROPERTY: "Propriété",
  VIEW_SPOT_CATEGORY: "Catégorie d'emplacement",
  DETAILS_SPOT_CATEGORY: "Détails de la catégorie d'emplacement",
  SAME_SPOT_CATEGORIES:
    'Un ou plusieurs catégories d\'emplacements existent déja avec le nom {name}. S\'agit-il de la même catégory ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  NO_DATA:
    "Vous n’avez ajouté aucune catégorie d'emplacement pour le moment, cliquer sur le bouton pour ajouter.",
  BOOKING_FORM_TYPE: "Type de formulaire de reservation",
  BOOKING_FORM_TYPE_DEFAULT: "Par défaut",
  BOOKING_FORM_TYPE_LAND: "Terrain",
  BOOKING_FORM_TYPE_PROPERTY: "Propriété",
};
