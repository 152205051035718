<template>
  <div class="kampwise-content">
    <notifications></notifications>
    <div
      class="kampwise-content_sidebar"
      ref="sidebar"
      v-bind:class="[isActive ? 'unfold' : 'fold']"
    >
      <div class="sidebar-toggle">
        <button @click="toggleClass()">
          <img src="/img/kw-sidebar.svg" alt="icon" />
        </button>
      </div>
      <div class="kampwise-content_sidebar_logo">
        <router-link :to="{ name: 'Dashboard' }">
          <span class="icon">
            <img src="/img/kampwise-icon.svg" alt="icon" />
          </span>
          <span class="text">
            <img src="/img/kampwise-name.svg" alt="name" />
          </span>
        </router-link>
      </div>
      <div class="kampwise-content_sidebar_menu">
        <main-navbar-apps></main-navbar-apps>
      </div>
      <div class="kampwise-content_sidebar_help">
        <div class="help-wrapper">
          <div class="title">
            <img src="/img/kw-support.svg" alt="icon" />
            <span>Aide et support</span>
          </div>
          <div class="description">
            <p>
              Aviez-vous des questions ? Notre documentation et support
              technique sont disponible.
            </p>
          </div>
          <div class="link">
            <a href="#">Support technique</a>
          </div>
        </div>
      </div>
    </div>
    <div class="kampwise-content_body">
      <MainHeader></MainHeader>
      <div class="kampwise-content_body_inner">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import MainNavbarApps from "./partials/MainNavbarApps.vue";
import NotificationsCount from "./partials/NotificationsCount.vue";
import MainHeader from "./partials/MainHeader.vue";

export default {
  components: {
    MainNavbarApps,
    // NotificationsCount,
    MainHeader,
  },

  mixins: [],

  data() {
    return {
      isActive: false,
      languages: ["fr", "en"],
      width: null,
      observer: null,
      positionTop: 0,
      positionLeft: 0,
      shouldFold: false,
    };
  },

  methods: {
    toggleClass(event) {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },

    selectLang(lang) {
      loadLanguageAsync(lang);
    },

    sidebarObserver() {
      const box = this.$refs.sidebar,
        vm = this,
        config = {
          attributes: true,
          childList: true,
        };
      const elementToObserve = document.querySelector(".elite-content_sidebar");
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          vm.width = entry.contentRect.width;
        });
      });
      observer.observe(elementToObserve, config);
      this.observer = observer;
    },

    /*getPosition() {
      this.positionTop = this.$refs["lang"].getBoundingClientRect().top;
      this.positionLeft = this.$refs["lang"].getBoundingClientRect().left;
      const dropdown = document.querySelector(".lang-switcher");
      if (this.isActive === false) {
        dropdown.style.cssText = `
          top: ${this.positionTop - 40}px;
          left: ${this.positionLeft}px;
        `;
      } else {
        dropdown.style.cssText = `bottom: 0; left: 0;`;
      }
    },*/

    /* menuLinkClickHandler() {
      this.shouldFold = true;
      setTimeout(
        function () {
          this.shouldFold = false;
        }.bind(this),
        1000
      );
    }, */

    menuHoverObserver() {
      const firstMenuLinks = document.querySelectorAll(
        ".elite-content_sidebar_level1_menu_wrapper > ul > li > a"
      );
      for (const menuLink of firstMenuLinks) {
        menuLink.removeEventListener("click", this.menuLinkClickHandler);
      }
      for (const menuLink of firstMenuLinks) {
        menuLink.addEventListener("click", this.menuLinkClickHandler);
      }
    },

    logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },

  async mounted() {
    // this.sidebarObserver();
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {
    me: {
      handler: debounce(function (val) {
        //this.menuHoverObserver();
      }),
      deep: true,
    },
  },
};
</script>
