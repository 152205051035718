export default {
  BOOKING_LIST: "Réservations",
  PENDING_BOOKING_LIST: "Réservations en cours",
  ADD_BOOKING: "Ajouter réservation",
  BOOKING_ADDED: "Réservation confirmée",
  EDIT_BOOKING: "Modifier la réservation",
  BOOKING_UPDATED: "Réservation mis à jour",
  DELETE_THIS_BOOKING: "Supprimer cette réservation ?",
  BOOKING_DELETED: "Réservation supprimée",
  CAPACITY: "Nombre de personnes",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Animaux",
  CHILDREN_CAPACITY: "Enfants",
  BOOK_SPOT: "Réserver place",
  STARTING_FROM: "À partir de",
  PRICE_PER_NIGHT: "par nuit",
  AVAILABILITY: "Disponibilité",
  AVAILABLE: "Disponible",
  NOT_AVAILABLE: "Non-disponible",
  PARTIALLY_AVAILABLE: "Partiellement disponible",
  FILTER_BY_SPOT_CATEGORY: "Terrain ou emplacement",
  FILTER_BY_SPOT_TYPE: "Type d’emplacement",
  FILTER_BY_SPOT_LENGTH: "Dimensions de l'équipement",
  FILTER_BY_EQUIPMENT: "Service",
  UNIT_FEET: "pieds",
  SPOTS_LIST_FOUND: "{total} emplacements trouvés",
  NEW_BOOKING: "Nouvelle réservation",
  BOOKING_CODE: "N° de confirmation",
  DURATION: "Nbr nuit",
  PRICE_PER_DAY: "Tarif/jour",
  ARRIVING_AT: "Arrivée",
  LEAVING_AT: "Départ",
  EXISTING_CUSTOMER: "Client existant",
  CREATE_CUSTOMER: "Nouveau client",
  SECURE_BOOKING: "Garantir",
  ADD_TO_CART: "Ajout",
  SPECIAL_REQUEST: "Demande du client",
  INTERNAL_NOTE: "Note interne",
  SELECT_CONFIRMATION_EXPIRATION_DATE:
    "Sélectionner une date d'expiration pour la confirmation",
  SEND_TO_CUSTOMER_EMAIL: "Envoyer à l'adresse courriel du client",
  CONFIRM_AND_SEND: "Confirmer et envoyer",
  BLOCK_THIS_SPOT: "Bloquer cet emplacement",
  VIEW_BOOKING: "Voir la reservation",
  PENDING: "En attente",
  BOOKINGS_LIST: "Liste des reservations",
  BOOKING_DETAILS: "Détails réservation",
  BOOKING_NUMBER: "Numéro de réservation",
  SERVICE: "Service",
  CONFIRM_THIS_BOOKING:
    "Voulez-vous vraiment confirmer et envoyer cette réservation à <span class='text-muted'>{email}</span> ?",
  CONFIRM_BOOKING: "Confirmation",
  BOOKING_CONFIRMED: "Reservation confirmée",
  CONFIRMED: "Non garantie",
  BOOKING_DAYS: "Jours réservés",
  BOOKING_SPOT: "Emplacement réservé",
  BOOKING_INFORMATION: "Information de réservation",
  OCCUPATION: "Occupation",
  SPOT_INFORMATION: "Information emplacement",
  SELECTED_PERIOD: "Période sélectionné",
  TOTAL_BOOKINGS: "{total} réservations",
  CONFORM_PAYMENT: "Confirmer paiement",
  BOOKING_IDENTIFIER: "# Réservation {code}",
  BOOKING_DATE: "Date de réservation",
  MY_CURRENT_BOOKINGS: "Ma liste de réservations",
  OTHERS_CURRENT_BOOKINGS: "Autre réservations",
  CONFIRM_THE_BOOKING: "Confirmer la réservation",
  BOOK_WITH_PAYMENT: "Réserver avec garantie",
  BOOK_WITHOUT_PAYMENT: "Réserver sans garantie",
  TAKE_CONTROL_OF_BOOKING:
    "<p>Un autre utilisateur ({email}) est entrain de travailler sur cette réservation</p><br> <b>Voulez vous quand même vous attribuer le contrôle ?</b>",
  BEDS_QTY: "Nombre de lits",
  ROOMS_QTY: "Nombre de chambres",
  SELECTED_EQUIPMENT: "Equipement choisi",

  REASON_REPAIR: "Réparation",
};
